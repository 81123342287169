.opciones-alta {
  height: 100%;
  border-radius: 1rem;
  padding: 1rem;
}
.opciones-alta input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.opciones-alta label {
  cursor: pointer;
}
/* Create a custom checkbox */
.checkmark {
  position: relative;
  height: 25px;
  display: block;
  top: 1.4rem;
  width: 25px;
  background-color: #eee;
  border-radius: 15px;
}

/* On mouse-over, add a grey background color */
label:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.opciones-alta input:checked ~ .checkmark {
  /*background-color: #2b388f;*/
  background-color: #FF8A19;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.opciones-alta input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.opciones-alta .checkmark:after {
  left: 10px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.position-text {
  margin-left: 2rem;
  margin-bottom: 0!important;
}
