.active{
    color:#2b388f !important;
    text-decoration: underline;
    min-width: 180px;
}

.link-wrapper{
    padding: 30px 1px 20px 7px;
}

.nomina-active > .nomina,
.cuenta-active > .cuenta,
.recursos-humanos-active > .recursos-humanos,
.juridico-active > .juridico,
.mantenimiento-active > .mantenimiento,
.undefined-active > .perfil,
.empresa-active > .empresa {
    padding: 8px;
    background-color: #FF8A19;
    border-radius: 4px;
}